import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class AppUrl {

  static get UNIVERSITY_URL(): string {
    return environment.appUrl + 'university/';
  }

  static get COLLEGE_URL(): string {
    return environment.appUrl + 'college-group/';
  }

  static get LOGIN(): string {
    return AppUrl.COLLEGE_URL + 'login';
  }

  static get SELF(): string {
    return AppUrl.COLLEGE_URL + 'self';
  }

  static UPDATE_SELF(): string {
    return AppUrl.COLLEGE_URL + 'update-self';
  }

  static LOGOUT(): string {
    return AppUrl.COLLEGE_URL + 'logout';
  }

  static FILE_UPLOAD(): string {
    return AppUrl.COLLEGE_URL + 'file-upload';
  }

  static STUDENT_DETAILS_BULK_UPLOAD(session_id?): string {
    if (session_id) {
      return AppUrl.UNIVERSITY_URL + 'student-bulk-upload/' + session_id;
    }
  }

  static QUESTION_PAPER_UPLOAD(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'question-paper-upload/' + unique_id;
    }
  }

  static UPDATE_USER_PASSWORD(): string {
    return AppUrl.UNIVERSITY_URL + 'update-user-password';
  }

  static COURSES(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'course/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'course';
    }
  }

  static COURSESBYPROGRAM(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'course-by-program/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'course-by-program';
    }
  }

  static COURSES_STATUS(id?): string {
    return AppUrl.COLLEGE_URL + 'course-status/' + id;
  }

  static NOTIFICATION_STATUS(id?): string {
    return AppUrl.COLLEGE_URL + 'notify-status/' + id;
  }

  static BRANCH(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'branch/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'branch';
    }
  }

  static BRANCH_STATUS(id?): string {
    return AppUrl.COLLEGE_URL + 'branch-status/' + id;
  }

  static STUDENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'student/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'student';
    }
  }

  static DELETE_REMARK_UPADTE(id): string {
    return AppUrl.COLLEGE_URL + 'delete_remark_update/' + id;
  }


  static STUDENT_BY_USER_ID(id): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'student/' + id;
    }
  }

  static STUDENT_VERIFY(id): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'verify-student/' + id;
    }
  }

  static STUDENT_FINAL_VERIFY(id): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'final-verify-student/' + id;
    }
  }

  static CENTER_STUDENT_AMOUNT(session_id): string {
    if (session_id) {
      return AppUrl.UNIVERSITY_URL + 'center-students-amount/' + session_id;
    }
  }

  static CENTER_STUDENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-students/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center-students';
    }
  }

  static DOWNLOAD_CENTER_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'download-center-students';
  }

  static DOWNLOAD_FILTER_STUDENT(): string {
    return AppUrl.COLLEGE_URL + 'download-filter-students';
  }

  static DOWNLOAD_FILTER_STUDENT_TRANSACTION(): string {
    return AppUrl.COLLEGE_URL + 'download-filter-students-transaction';
  }

  static DIRECT_STUDENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'student/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'student';
    }
  }


  static DELETED_STUDENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'deleted-student/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'deleted-student';
    }
  }

  static CENTER_BRANCH_STUDENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-branch-students/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center-branch-students';
    }
  }

  static PROCESS_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'process-student';
  }

  static STUDENT_EVALUATION(): string {
    return AppUrl.UNIVERSITY_URL + 'student-evaluation';
  }

  static BRANCH_STUDENT(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'branch-students/' + unique_id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'branch-students';
    }
  }

  static TOTAL_STUDENT_COUNT(id): string {
    return AppUrl.COLLEGE_URL + 'total-student-count/' + id;
  }

  static TOTAL_VERIFIED_STUDENT_COUNT(id): string {
    return AppUrl.COLLEGE_URL + 'total-verified-student-count/' + id;
  }

  static TOTAL_ENROLLED_STUDENT_COUNT(id): string {
    return AppUrl.COLLEGE_URL + 'total-enrolled-student-count/' + id;
  }

  static TOTAL_DOCUMENT_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'docs-count';
  }

  static FRESH_STUDENT_COUNT(id): string {
    return AppUrl.UNIVERSITY_URL + 'fresh-student-count/' + id;
  }

  static LATERAL_ENTRY_STUDENT_COUNT(id): string {
    return AppUrl.UNIVERSITY_URL + 'lateral-entry-student-count/' + id;
  }

  static RE_REGISTER_STUDENT_COUNT(id): string {
    return AppUrl.UNIVERSITY_URL + 're-register-student-count/' + id;
  }

  static ADD_STUDENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'add-student/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'add-student';
    }
  }

  static RESEND_OTP_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'resend-otp-student';
  }

  static STUDENTS_DOCUMENT_DOWNLOAD(id): string {
    return AppUrl.UNIVERSITY_URL + 'student-document-download/' + id;
  }

  static ASSIGN_ENROLLMENT(id): string {
    return AppUrl.UNIVERSITY_URL + 'assign-enrollment/' + id;
  }

  static ASSIGN_ROLL_NO(id): string {
    return AppUrl.UNIVERSITY_URL + 'assign-roll-no/' + id;
  }

  static RE_REGISTER_STUDENT(id): string {
    return AppUrl.COLLEGE_URL + 're-register-student/' + id;
  }

  /*
    static DOWNLOAD_STUDENT(): string {
      return AppUrl.UNIVERSITY_URL + 'download-students';
    }
  */

  static DOWNLOAD_RE_REGISTERED_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'download-center-re-registered-students';
  }

  static VERIFY_OTP_STUDENT(): string {
    return AppUrl.UNIVERSITY_URL + 'verify-otp-student';
  }

  static STATES(): string {
    return AppUrl.COLLEGE_URL + 'states';
  }

  static COLLEGE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'college/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'college';
    }
  }


  static COLLEGEBYUSER(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'college-by-user/' + id;
    }
  }

  static CENTER(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center';
    }
  }

  static CENTER_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'center-count';
  }

  static CENTER_BRANCH_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'center-branch-count';
  }

  static CENTER_BY_UNIQUE_ID(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-by-unique-id/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center-by-unique-id';
    }
  }

  static CENTER_BRANCHES(id): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-branches/' + id;
    }
  }

  static CENTER_PASSWORD(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'center-password/' + unique_id;
    }
  }

  static ASSIGN_CENTER_ADMIN(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'assign-center-admin/' + unique_id;
    }
  }

  static CENTER_AMOUNT(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'center-amount/' + unique_id;
    }
  }

  static CENTER_ADDED_AMOUNT(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'center-added-amount/' + unique_id;
    }
  }

  static CENTER_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-status/' + id;
    }
  }

  static SESSIONS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'session/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'session';
    }
  }

  static STUDENT_FEE_ADD(): string {
    return AppUrl.COLLEGE_URL + 'add-student-fee'
  }


  static STUDENT_FEE_DISCOUNT(): string {
    return AppUrl.COLLEGE_URL + 'discount-student-fee'
  }

  static ALL_TRANSACTION(): string {
    return AppUrl.COLLEGE_URL + 'transaction';
  }

  static TRANSACTION_DEATILS(): string {
    return AppUrl.COLLEGE_URL + 'transaction-details';
  }


  static TRANSACTION_BY_CORSES(): string {
    return AppUrl.COLLEGE_URL + 'transaction-by-course';
  }

  static STUDENT_BY_COURSE(): string {
    return AppUrl.COLLEGE_URL + 'student-by-course';
  }

  static EXTERNAL_MONEY_ADD(): string {
    return AppUrl.COLLEGE_URL + 'create-receipt'
  }

  static PAYMENT_STATUS(uniqueId?): string {
    return AppUrl.COLLEGE_URL + 'transaction-status/' + uniqueId;
  }


  static ENROLLMENT_UPDATE(uniqueId?): string {
    return AppUrl.COLLEGE_URL + 'assign-enrollment/' + uniqueId;
  }

  static UNIREGSTATION_UPDATE(uniqueId?): string {
    return AppUrl.COLLEGE_URL + 'update-university-registration/' + uniqueId;
  }


  static COLLEGES(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'college/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'college';
    }
  }

  static REFERRED_BY(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'referred_by/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 'referred_by';
    }
  }

  static SESSIONS_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'session-count';
  }

  static COLLEGES_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'college-count';
  }

  static REFERRED_BY_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'referred-by-count';
  }

  static Transaction_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'transaction-count';
  }

  static SESSION_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'session-status/' + uniqueId;
    }
  }

  static COLLEGE_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'college-status/' + uniqueId;
    }
  }


  static REFERRED_BY_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 'referred_by-status/' + uniqueId;
    }
  }

  static DEFAULT_SESSION(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'default-session/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'default-session';
    }
  }

  static ACTIVE_SESSION_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'active-session-count';
  }

  static RE_REGISTER_SESSION_COUNT(): string {
    return AppUrl.COLLEGE_URL + 're-register-session-count';
  }

  static RE_REGISTRATION_SESSION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 're-registration-session/' + id;
    }
  }

  static RE_REGISTRATION_ENTRY_SESSION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 're-registration-entry-session/' + id;
    }
  }

  static ADMISSION_SESSION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'admission-session/' + id;
    }
  }

  static RE_OPENED_SESSION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 're-opened-session/' + id;
    }
  }

  static RE_REGISTER_SESSIONS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 're-register-session/' + uniqueId;
    } else {
      return AppUrl.COLLEGE_URL + 're-register-session';
    }
  }

  static RE_REGISTER_SESSIONS_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 're-register-session-count';
  }

  static RE_REGISTER_SESSION_STATUS(uniqueId?): string {
    if (uniqueId) {
      return AppUrl.COLLEGE_URL + 're-register-session-status/' + uniqueId;
    }
  }

  static GET_SHARE_PERCENTAGE(): string {
    return AppUrl.UNIVERSITY_URL + 'share-percentage';
  }

  static CENTER_ADMISSION_REPORT(): string {
    return AppUrl.UNIVERSITY_URL + 'center-admission-report';
  }

  static CENTER_RE_REGISTRATION_REPORT(): string {
    return AppUrl.UNIVERSITY_URL + 'center-re-registration-report';
  }

  static UPDATE_SHARE_PERCENTAGE(user_id, session_id): string {
    return AppUrl.UNIVERSITY_URL + 'update-share-percentage/' + user_id + '/' + session_id;
  }

  static ADDING_BRANCH_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'adding-branch-status/' + id;
    }
  }

  static UPDATE_VISIBLE_SESSIONS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'visible-session/' + id;
    }
  }

  static PERSONAL_INFO(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'personal-info/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'personal-info';
    }
  }

  static TRANSLATE_TEXT(): string {
    return AppUrl.UNIVERSITY_URL + 'translate-text';
  }

  static PERSONAL_INFO_PHOTOGRAPH_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'personal-info-photograph/' + id;
    }
  }

  static PERSONAL_INFO_SIGNATURE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'personal-info-student-signature/' + id;
    }
  }

  static ACADEMIC_DETAILS_TRANSFER_CERTIFICATE_UPDATE(id?): string {
    return AppUrl.COLLEGE_URL + 'academic-details-transfer-certificate/' + id;
  }

  static PERSONAL_INFO_GOVT_PHOTO_ID_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'personal-info-govt-photo-id-proof/' + id;
    }
  }


  static ADMIT_CARD_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'student-doc-admit-card-upload/' + id;
    }
  }

  static ID_CARD_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'student-doc-id-card-upload/' + id;
    }
  }

  static ADMIT_CARD_STATUS(id): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'admit-card-status-update/' + id;
    }
  }

  static UPLOAD_EXAM_RESULT(id): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'exam-result-uploaded/' + id;
    }
  }
  static  MARKED_BACK_SUBJECT(id): string {
    return AppUrl.COLLEGE_URL + 'marked-back-subject/' + id;
  }

  static  MARKED_BACK_FEE_STATUS(id): string {
    return AppUrl.COLLEGE_URL + 'marked-back-fee-status/' + id;
  }

  static ADD_LATE_FEES(): string {
    return AppUrl.COLLEGE_URL + 'add-late-fees';
  }

  static ADDRESS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'address/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'address';
    }
  }

  static ACADEMIC_DETAILS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'academic-details/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'academic-details';
    }
  }

  static ACADEMIC_DETAILS_TENTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'academic-details-tenth-certificate/' + id;
    }
  }

  static ACADEMIC_DETAILS_TWELTH_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'academic-details-twelth-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'more-qualification/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'more-qualification';
    }
  }

  static MORE_QUALIFICATION_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'more-qualification-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_POST_GRADUATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'more-qualification-post-graduation-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_OTHER_QUALIFICATION_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'more-qualification-other-qualification-certificate/' + id;
    }
  }

  static MORE_QUALIFICATION_PREVIOUS_CERTIFICATE_UPDATE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'more-qualification-previous-certificate/' + id;
    }
  }

  static PROGRAMS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'program/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'program';
    }
  }

  static PROGRAMS_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'program-status/' + id;
    }
  }

  static PROGRAM_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'program-count';
  }

  static COURSE_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'course-count';
  }

  static BRANCH_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'branch-count';
  }

  static PCB_MAPPINGS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'pcb-mapping/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'pcb-mapping';
    }
  }

  static PCB_MAPPINGS_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'pcb-mapping-status/' + id;
    }
  }

  static SUBJECT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'subject/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'subject';
    }
  }
  static SUBJECT_REMARK_ADD(id): string {
    return AppUrl.COLLEGE_URL + 'subject-remark-add/' + id;
  }

  static DELETED_SUBJECT(): string {
    return AppUrl.COLLEGE_URL + 'deleted-subject';
  }

  static FEESTRUCTURE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'fee-structure/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'fee-structure';
    }
  }


  static FEESTRUCTUREDETAIL(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'fee-structure-detail/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'fee-structure-detail';
    }
  }

  static SUBJECT_SLM(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'subject-slm/' + id;
    }
  }

  static SUBJECT_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'subject-status/' + id;
    }
  }

  static SUBJECT_MAPPING(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'subject-mapping/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'subject-mapping';
    }
  }

  static BRANCH_FILTER(course_id?): string {
    return AppUrl.COLLEGE_URL + 'pcb-mapping-by-course/' + course_id
  }


  static PCB_BY_PCB(): string {
    return AppUrl.COLLEGE_URL + 'pcb-mapping-by-pcb';
  }

  static SUBJECT_MAPPING_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'subject-mapping-status/' + id;
    }
  }

  static SUBJECT_MAPPING_ASSIGNMENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'subject-mapping-assignment/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'subject-mapping-assignment';
    }
  }

  static SUBJECT_MAPPING_ASSIGNMENT_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'subject-mapping-assignment-status/' + id;
    }
  }

  static SUBJECT_ASSIGNMENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'subject-mapping-assignment/' + id;
    }
  }

  static ASSIGNMENT(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'assignment/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'assignment';
    }
  }

  static ASSIGNMENT_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'assignment-status/' + id;
    }
  }

  static COURSE_DETAILS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'course-details/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'course-details';
    }
  }

  static COURSE_CURRICULUM(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'course-curriculum/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'course-curriculum';
    }
  }

  static COURSE_CURRICULUM_BY_PCB_MAPPING(): string {
    return AppUrl.COLLEGE_URL + 'course-curriculum-by-pcb';
  }

  static PROFILE_IMAGE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'profile-image/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'profile-image';
    }
  }

  static CREDIT_TRANSACTIONS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'credit-transaction/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'credit-transaction';
    }
  }

  static CENTER_CREDIT_TRANSACTIONS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'center-credit-transaction/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'center-credit-transaction';
    }
  }

  static RECEIPTS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'receipt/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'receipt';
    }
  }

  static DOWNLOAD_RECEIPTS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'download-receipt/' + id;
    }
  }

  static RECEIPTS_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'receipt-status/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'receipt-status';
    }
  }

  static TICKETS(): string {
    return AppUrl.COLLEGE_URL + 'support-ticket';
  }

  static TICKET_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'support-ticket-report-count';
  }

  static TICKET_STATUS(unique_id): string {
    return AppUrl.COLLEGE_URL + 'support-ticket-status/' + unique_id;
  }

  static ISSUES(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'issues/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'issues';
    }
  }

  static ISSUE_REPORT_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-report-count';
  }

  static ISSUE_ELEARNING_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_EXAMINATION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_MARKSHEET_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_MIGRATION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_ORIGINAL_DEGREE_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_RE_REGISTRATION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_OTHER_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_REVALUATION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_TRANSCRIPT_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_ADMISSION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-reregistration-count';
  }

  static ISSUE_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'issues-count';
  }

  static ISSUE_DETAILS(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'issues/' + unique_id;
  }

  static ISSUE_STATUS(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'issues-status/' + unique_id;
  }

  static ISSUE_PAYMENT_STATUS(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'issues-payment-status/' + unique_id;
  }

  static ISSUE_EDIT_STATUS(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'issues-edit-status/' + unique_id;
  }

  static ISSUE_COURIER_RECEIPT(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'issues-courier-receipt/' + unique_id;
  }

  static PERMISSIONS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'permission/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'permission';
    }
  }

  static PERMISSIONS_STATUS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'permission-status/' + unique_id;
    }
  }

  static PERMISSION_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'permission-count';
  }

  static SUB_PERMISSIONS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'sub-permission/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'sub-permission';
    }
  }

  static SUB_PERMISSIONS_STATUS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'sub-permission-status/' + unique_id;
    }
  }

  static SUB_PERMISSION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'sub-permission-count';
  }

  static ROLES(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'role/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'role';
    }
  }

  static ROLES_STATUS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'role-status/' + unique_id;
    }
  }

  static ROLE_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'role-count';
  }

  static ROLE_PERMISSIONS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'role-permission/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'role-permission';
    }
  }

  static ROLE_PERMISSIONS_STATUS(unique_id?): string {
    if (unique_id) {
      return AppUrl.UNIVERSITY_URL + 'role-permission-status/' + unique_id;
    }
  }

  static ROLE_PERMISSION_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'role-permission-count';
  }

  static DELETE_ALL_ROLE_PERMISSIONS(unique_id): string {
    return AppUrl.UNIVERSITY_URL + 'delete-role-permission-by-role-id/' + unique_id;
  }

  static DEPARTMENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'department/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'department';
    }
  }

  static NOTIFICATION(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'notify/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'notify';
    }
  }


  static STAGES(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'stage/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'stage';
    }
  }
  static SR_MAPPING(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'sr-mapping/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'sr-mapping';
    }
  }

  static REASON(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'reason/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'reason';
    }
  }
  static ENQUIRY(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'enquiry/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'enquiry';
    }
  }

  static ENQUIRY_FOLLOWUP(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'enquiry-follow-up/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'enquiry-follow-up';
    }
  }

  static DEPARTMENT_COUNT(): string {
    return AppUrl.COLLEGE_URL + 'department-count';
  }

  static DEPARTMENT_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'department-status/' + id;
    }
  }

  static DEPARTMENT_BY_UNIQUE_ID(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'department-by-unique-id/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'department-by-unique-id';
    }
  }

  static EMPLOYEE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'employee/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'employee';
    }
  }

  static EMPLOYEE_COUNT(): string {
    return AppUrl.UNIVERSITY_URL + 'employee-count';
  }

  static EMPLOYEE_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'employee-status/' + id;
    }
  }

  static EMPLOYEE_MARK_AS_HOD(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'employee-mark-as-hod/' + id;
    }
  }

  static EMPLOYEE_DEPARTMENT(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'employee-department/' + id;
    }
  }

  static EMPLOYEE_BY_UNIQUE_ID(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'employee-by-unique-id/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'employee-by-unique-id';
    }
  }

  static EMPLOYEE_PASSWORD(id): string {
    return AppUrl.COLLEGE_URL + 'employee-password/' + id;
  }

  static EXAM_SESSIONS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'exam-session/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'exam-session';
    }
  }


  static EXAM_TIMETABLE(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'exam-time-table/' + id;
    } else {
      return AppUrl.COLLEGE_URL + 'exam-time-table';
    }
  }

  static EXAM_TIMETABLE_STATUS(id?): string {
    if (id) {
      return AppUrl.COLLEGE_URL + 'exam-time-table-status/' + id;
    }
  }

  static EXAM_SESSIONS_STATUS(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'exam-session-status/' + id;
    }
  }

  static SCHEDULE_EXAM(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'schedule-exam/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'schedule-exam';
    }
  }

  static SCHEDULE_EXAM_RESULTS(): string {
    return AppUrl.UNIVERSITY_URL + 'schedule-exam-results';
  }

  static QUESTION_PAPER(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'question-paper/' + id;
    } else {
      return AppUrl.UNIVERSITY_URL + 'question-paper';
    }
  }

  static DELETE_QUESTION_PAPER_OBJECTIVE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'question-paper-objective/' + id;
    }
  }

  static DELETE_QUESTION_PAPER_SUBJECTIVE(id?): string {
    if (id) {
      return AppUrl.UNIVERSITY_URL + 'question-paper-subjective/' + id;
    }
  }

  static TRANSFER_STUDENT_REQUESTS(): string {
    return AppUrl.UNIVERSITY_URL + 'transfer-student-requests';
  }

  static TRANSFER_STUDENT_STATUS(id): string {
    return AppUrl.UNIVERSITY_URL + 'transferred-student-status/' + id;
  }

  static UPDATE_MARKED_STUDENT_BACK_EXAM_STATUS(id?): string {
    return AppUrl.UNIVERSITY_URL + 'update-marked-student-back-exam-status/' + id;
  }

  static BACK_EXAM_STUDENTS(): string {
    return AppUrl.UNIVERSITY_URL + 'back-exam-student';
  }

  static LIVE_LECTURE_TOKEN(): string {
    return AppUrl.UNIVERSITY_URL + 'live-lecture-token';
  }

  static DOCS(unique_id?): string {
    if (unique_id) {
      return AppUrl.COLLEGE_URL + 'docs/' + unique_id;
    } else {
      return AppUrl.COLLEGE_URL + 'docs';
    }
  }
}
