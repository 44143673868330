import {HostListener, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UniversityService} from './university.service';
import {StorageService} from './storage.service';
import {PermissionConstant} from '../constants/permission-constant';
import {Helper} from '../constants/helper';

// Menu
export interface Menu {
  path?: string;
  title?: string;
  icon?: string;
  type?: string;
  badgeType?: string;
  badgeValue?: string;
  active?: boolean;
  availableTo?: string[];
  bookmark?: boolean;
  children?: Menu[];
}

@Injectable({
  providedIn: 'root'
})

export class NavService {
  user: any;
  userData: any;

  constructor(private universityService: UniversityService) {
    this.onResize();
    this.user = StorageService.getItem('self');
    this.getUserDetail();
    if (this.screenWidth < 991) {
      this.collapseSidebarMainMenu = false;
      this.collapseSidebarSecondMenu = true;
    }
  }

  public openclass: boolean = false;
  public screenWidth: any;

  public collapseSidebarMainMenu = false;
  public collapseSidebarSecondMenu = false;

  // For Horizontal Layout Mobile
  public horizontal: boolean = window.innerWidth >= 991;

  public fullScreen = false;

  MENUITEMS: Menu[] = [
    {
      title: 'General',
      path: '/general/home',
      icon: 'pe-7s-home',
      type: 'sub',
      active: true,
      availableTo: [PermissionConstant.ALL],
      children:
        [
          {
            title: 'General', type: 'sub', active: true, children: [
              {
                path: '/general/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ALL]
              }
            ]
          }
        ],
    },
    {
      title: 'Colleges',
      path: '/college/home',
      icon: 'fas fa-sliders-h',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_COLLEGE, PermissionConstant.LIST_COLLEGE],
      children:
        [
          {
            title: 'College', type: 'sub', active: true, children: [
              {
                path: '/college/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_COLLEGE, PermissionConstant.LIST_COLLEGE],
              },
              {
                path: '/college/add-college',
                title: 'Add College',
                type: 'link',
                availableTo: [PermissionConstant.ADD_COLLEGE]
              },
              {
                path: '/college/list-college',
                title: 'List College',
                type: 'link',
                availableTo: [PermissionConstant.LIST_COLLEGE]
              }
            ]
          }
        ],
    },
    {
      title: 'Sessions',
      path: '/sessions/home',
      icon: 'fas fa-sliders-h',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_SESSION, PermissionConstant.LIST_SESSION],
      children:
        [
          {
            title: 'Sessions', type: 'sub', active: true, children: [
              {
                path: '/sessions/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION, PermissionConstant.LIST_SESSION],
              },
              {
                path: '/sessions/add-session',
                title: 'Add Session',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION]
              },
              {
                path: '/sessions/list-session',
                title: 'List Session',
                type: 'link',
                availableTo: [PermissionConstant.LIST_SESSION]
              },
              {
                path: '/sessions/re-register-session',
                title: 'Re-Register Session',
                type: 'link',
                availableTo: [PermissionConstant.ADD_SESSION]
              },
              {
                path: '/sessions/list-re-registered-session',
                title: 'List Re-Registered Session',
                type: 'link',
                availableTo: [PermissionConstant.LIST_SESSION]
              },
            ]
          }
        ],
    },
    {
      title: 'Modules',
      path: '/modules/home',
      icon: 'fas fa-bookmark',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.PROGRAMS, PermissionConstant.COURSES, PermissionConstant.BRANCHES, PermissionConstant.MAPPINGS, PermissionConstant.SUBJECTS],
      children:
        [
          {
            title: 'Modules', type: 'sub', active: true, children: [
              {
                path: '/modules/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.PROGRAMS, PermissionConstant.COURSES, PermissionConstant.BRANCHES, PermissionConstant.MAPPINGS, PermissionConstant.SUBJECTS],
              },
              {
                path: '/modules/programs',
                title: 'Programs',
                type: 'link',
                availableTo: [PermissionConstant.PROGRAMS],
              },
              {
                path: '/modules/courses',
                title: 'Courses',
                type: 'link',
                availableTo: [PermissionConstant.COURSES],
              },
              {
                path: '/modules/branches',
                title: 'Branches',
                type: 'link',
                availableTo: [PermissionConstant.BRANCHES],
              },
              /* {
               path: '/modules/mappings',
               title: 'Mappings',
               type: 'link',
               availableTo: [PermissionConstant.MAPPINGS],
             },*/
              {
                path: '/modules/subjects',
                title: 'Subjects',
                type: 'link',
                availableTo: [PermissionConstant.SUBJECTS],
              },
              {
                path: '/modules/deleted-subjects',
                title: 'Deleted Subjects',
                type: 'link',
                availableTo: [PermissionConstant.SUBJECTS],
              }
            ]
          }
        ],
    },
    {
      title: 'Referred By',
      path: '/referred-by/home',
      icon: 'pe-7s-helm',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_REFERRED_BY, PermissionConstant.LIST_REFERRED_BY],
      children:
        [
          {
            title: 'Referred By', type: 'sub', active: true, children: [
              {
                path: '/referred-by/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_REFERRED_BY, PermissionConstant.LIST_REFERRED_BY],
              },
              {
                path: '/referred-by/add-referred-by',
                title: 'Add Referred By',
                type: 'link',
                availableTo: [PermissionConstant.ADD_REFERRED_BY]
              },
              {
                path: '/referred-by/list-referred-by',
                title: 'List Referred By',
                type: 'link',
                availableTo: [PermissionConstant.LIST_REFERRED_BY]
              }
            ]
          }
        ],
    },
    {
      title: 'Students',
      path: '/students/home',
      icon: 'fas fa-user-graduate',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_STUDENTS, PermissionConstant.DIRECT_STUDENTS, PermissionConstant.DELETED_STUDENTS],
      children:
        [
          {
            title: 'Student', type: 'sub', active: true, children: [
              {
                path: '/students/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_STUDENTS, PermissionConstant.DIRECT_STUDENTS, PermissionConstant.DELETED_STUDENTS]
              },
              {
                path: '/students/add-student',
                title: 'Add Student',
                type: 'link',
                availableTo: [PermissionConstant.ADD_STUDENTS]
              },
              {
                path: '/students/direct-students',
                title: 'List Students',
                type: 'link',
                availableTo: [PermissionConstant.DIRECT_STUDENTS]
              },
              {
                path: '/students/re-register-students',
                title: 'Re-Register Students',
                type: 'link',
                availableTo: [PermissionConstant.RE_REGISTER_STUDENTS]
              },
              {
                path: '/students/deleted-student',
                title: 'Deleted Students',
                type: 'link',
                availableTo: [PermissionConstant.DELETED_STUDENTS]
              },
              {
                path: '/students/back-student',
                title: 'Back Student List',
                type: 'link',
                availableTo: [PermissionConstant.DELETED_STUDENTS]
              },
            ]
          }
        ],
    },
    {
      title: 'Accounts',
      path: '/accounts/home',
      icon: 'fas fa-credit-card',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.OTHER_RECEIPTS, PermissionConstant.STUDENT_TRANSACTIONS],
      children:
        [
          {
            title: 'Accounts', type: 'sub', active: true, children: [
              {
                path: '/accounts/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_RECEIPTS, PermissionConstant.STUDENT_TRANSACTIONS]
              },
              {
                path: '/accounts/centers',
                title: 'Receipts',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_RECEIPTS]
              },
              {
                path: '/accounts/centers-transactions',
                title: 'Student Transactions',
                type: 'link',
                availableTo: [PermissionConstant.STUDENT_TRANSACTIONS]
              },
            ]
          }
        ],
    },
    {
      title: 'Support',
      path: '/support/home',
      icon: 'pe-7s-headphones',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADMISSION_TICKETS, PermissionConstant.RE_REGISTRATION_TICKETS, PermissionConstant.EXAMINATION_TICKETS, PermissionConstant.MARKSHEET_TICKETS, PermissionConstant.TRANSCRIPT_TICKETS, PermissionConstant.REVALUATION_TICKETS, PermissionConstant.MIGRATION_TICKETS, PermissionConstant.ORIGINAL_DEGREE_TICKETS, PermissionConstant.E_LEARNING_TICKETS, PermissionConstant.OTHER_TICKETS],
      children:
        [
          {
            title: 'Support', type: 'sub', active: true, children: [
              {
                path: '/support/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADMISSION_TICKETS, PermissionConstant.RE_REGISTRATION_TICKETS, PermissionConstant.EXAMINATION_TICKETS, PermissionConstant.MARKSHEET_TICKETS, PermissionConstant.TRANSCRIPT_TICKETS, PermissionConstant.REVALUATION_TICKETS, PermissionConstant.MIGRATION_TICKETS, PermissionConstant.ORIGINAL_DEGREE_TICKETS, PermissionConstant.E_LEARNING_TICKETS, PermissionConstant.OTHER_TICKETS]
              },
              {
                path: '/support/admission-tickets',
                title: 'Admission Tickets',
                type: 'link',
                availableTo: [PermissionConstant.ADMISSION_TICKETS]
              },
              {
                path: '/support/re-registration-tickets',
                title: 'Re-Registration Tickets',
                type: 'link',
                availableTo: [PermissionConstant.RE_REGISTRATION_TICKETS]
              },
              {
                path: '/support/examination-tickets',
                title: 'Examination Tickets',
                type: 'link',
                availableTo: [PermissionConstant.EXAMINATION_TICKETS]
              },
              {
                path: '/support/marksheet-tickets',
                title: 'Marksheet Tickets',
                type: 'link',
                availableTo: [PermissionConstant.MARKSHEET_TICKETS]
              },
              {
                path: '/support/transcript-tickets',
                title: 'Transcript Tickets',
                type: 'link',
                availableTo: [PermissionConstant.TRANSCRIPT_TICKETS]
              },
              {
                path: '/support/revaluation-tickets',
                title: 'Revalution Tickets',
                type: 'link',
                availableTo: [PermissionConstant.REVALUATION_TICKETS]
              },
              {
                path: '/support/migration-tickets',
                title: 'Migration Tickets',
                type: 'link',
                availableTo: [PermissionConstant.MIGRATION_TICKETS]
              },
              {
                path: '/support/original-degree-tickets',
                title: 'Original Degree Tickets',
                type: 'link',
                availableTo: [PermissionConstant.ORIGINAL_DEGREE_TICKETS]
              },
              {
                path: '/support/e-learning-tickets',
                title: 'E-Learning Tickets',
                type: 'link',
                availableTo: [PermissionConstant.E_LEARNING_TICKETS]
              },
              {
                path: '/support/other-tickets',
                title: 'Other Tickets',
                type: 'link',
                availableTo: [PermissionConstant.OTHER_TICKETS]
              }
            ]
          }
        ],
    },
    {
      title: 'Dept.',
      path: '/departments/home',
      icon: 'fas fa-chalkboard',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ADD_DEPARTMENT, PermissionConstant.LIST_DEPARTMENT, PermissionConstant.ADD_EMPLOYEE, PermissionConstant.LIST_EMPLOYEE, PermissionConstant.ADD_VIDEO_LECTURE, PermissionConstant.LIST_VIDEO_LECTURE, PermissionConstant.ADD_SLM, PermissionConstant.LIST_SLM],
      children:
        [
          {
            title: 'Departments', type: 'sub', active: true, children: [
              {
                path: '/departments/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ADD_DEPARTMENT, PermissionConstant.LIST_DEPARTMENT, PermissionConstant.ADD_EMPLOYEE, PermissionConstant.LIST_EMPLOYEE, PermissionConstant.ADD_VIDEO_LECTURE, PermissionConstant.LIST_VIDEO_LECTURE, PermissionConstant.ADD_SLM, PermissionConstant.LIST_SLM]
              },
              {
                path: '/departments/add-department',
                title: 'Add Department',
                type: 'link',
                availableTo: [PermissionConstant.ADD_DEPARTMENT]
              },
              {
                path: '/departments/list-department',
                title: 'List Department',
                type: 'link',
                availableTo: [PermissionConstant.LIST_DEPARTMENT]
              },
              {
                path: '/departments/add-employee',
                title: 'Add Employee',
                type: 'link',
                availableTo: [PermissionConstant.ADD_EMPLOYEE]
              },
              {
                path: '/departments/list-employee',
                title: 'List Employee',
                type: 'link',
                availableTo: [PermissionConstant.LIST_EMPLOYEE]
              }
            ]
          }
        ],
    },
    {
      title: 'Access',
      path: '/access/home',
      icon: 'fas fa-globe',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.ROLES, PermissionConstant.PERMISSIONS],
      children:
        [
          {
            title: 'Accessibility', type: 'sub', active: true, children: [
              {
                path: '/access/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.ROLES, PermissionConstant.PERMISSIONS]
              },
              {
                path: '/access/roles',
                title: 'Roles',
                type: 'link',
                availableTo: [PermissionConstant.ROLES]
              },
              {
                path: '/access/permissions',
                title: 'Permissions',
                type: 'link',
                availableTo: [PermissionConstant.PERMISSIONS]
              },
            ]
          }
        ],
    },
    {
      title: 'Docs',
      path: '/docs/home',
      icon: 'pe-7s-study',
      type: 'sub',
      active: false,
      availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_CMM, PermissionConstant.DOCS_CMM_VERIFY_DOC, PermissionConstant.DOCS_CMM_VERIFY_AMOUNT, PermissionConstant.DOCS_CMM_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_CMM_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_CMM_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_ORIGINAL_DEGREE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_DOC, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_CMM_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL, PermissionConstant.DOCS_CMM_PRINT_LABEL, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL],
      children:
        [
          {
            title: 'Docs', type: 'sub', active: true, children: [
              {
                path: '/docs/home',
                title: 'Home',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_CMM_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL, PermissionConstant.DOCS_CMM_PRINT_LABEL, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL]
              },
              {
                path: '/docs/marksheet',
                title: 'Marksheet',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_MARKSHEET, PermissionConstant.DOCS_MARKSHEET_VERIFY_DOC, PermissionConstant.DOCS_MARKSHEET_VERIFY_AMOUNT, PermissionConstant.DOCS_MARKSHEET_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_MARKSHEET_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_MARKSHEET_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_MARKSHEET_VIEW_AMOUNT, PermissionConstant.DOCS_MARKSHEET_PRINT_LABEL]
              },

              {
                path: '/docs/original-degree',
                title: 'Original Degree',
                type: 'link',
                availableTo: [PermissionConstant.DOCS_ORIGINAL_DEGREE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_DOC, PermissionConstant.DOCS_ORIGINAL_DEGREE_VERIFY_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_PRINT_STATUS, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_CONSIGNMENT_NO, PermissionConstant.DOCS_ORIGINAL_DEGREE_UPDATE_DELIVERY_DATE, PermissionConstant.DOCS_ORIGINAL_DEGREE_VIEW_AMOUNT, PermissionConstant.DOCS_ORIGINAL_DEGREE_PRINT_LABEL]
              }
            ]
          }
        ],
    },
    {
      title: 'Exam', path: '/exam/add-timetable', icon: 'fas fa-calendar', type: 'sub', active: false, children: [{
        title : 'Exam' , type: 'sub' , active: true , children: [
          // {path: '/exam/home' , title: 'Home' , type: 'link'},
          {path: '/exam/add-timetable', title: 'Add ExamTime Table', type: 'link'},
          {path: '/exam/exam-result', title: 'Exam Result', type: 'link'},
        ]
      }]
    },
    {
      title: 'Notification', path: '/notify/add', icon: 'fas fa-bell', type: 'sub', active: false, children:
        [
          {
            title: 'Notification', type: 'sub', active: true, children: [
              // {path: '/notify/home', title: 'Home', type: 'link'},
              {path: '/notify/add', title: 'Add Notification', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Levels', path: '/level/stages', icon: 'fas fa-stairs', type: 'sub', active: false, children:
        [
          {
            title: 'Levels', type: 'sub', active: true, children: [
              {path: '/level/stages', title: 'Stages', type: 'link'},
              {path: '/level/reasons', title: 'Reasons', type: 'link'},
              {path: '/level/sr-mappings', title: 'SR Mappings', type: 'link'},
            ]
          }
        ],
    },
    {
      title: 'Enquiry', path: '/enquiry/add', icon: 'fas fa-question-circle', type: 'sub', active: false, children:
        [
          {
            title: 'Enquiry', type: 'sub', active: true, children: [
              // {path: '/enquiry/home', title: 'Home', type: 'link'},
              {path: '/enquiry/add', title: 'Add Enquiry', type: 'link'},
            ]
          }
        ],
    }
  ];
  // Array
  items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

  getUserDetail() {
    this.universityService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.userData = data;
        if (this.userData && this.userData['id'] !== 1) {
          let rolePermissions = [];
          if (this.userData && this.userData['role'] && this.userData['role']['role_permissions'] && this.userData['role']['role_permissions'].length > 0 && this.userData['role']['status'] && this.userData['role']['status'] === 'ACTIVE') {
            this.userData['role']['role_permissions'].forEach(subPermission => {
              if (subPermission && subPermission['sub_permission'] && subPermission['sub_permission']['meta']) {
                rolePermissions.push(subPermission['sub_permission']['meta']);
              }
            });
          }
          /*this.MENUITEMS.forEach(menu => {
            menu['is_hidden'] = menu && menu.title !== 'Students' && this.userData['user_type'] && this.userData['user_type'] === 'ACADEMIC_STAFF';
            if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
              if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                menu['children'][0]['children'].forEach(child => {
                  child['is_hidden'] = child && child.title !== 'Final Verification' && this.userData['user_type'] && this.userData['user_type'] === 'ACADEMIC_STAFF';
                });
              }
            }
          });*/
          this.MENUITEMS.forEach(menu => {
            if (menu && menu.availableTo) {
              if (!menu['availableTo'].includes('ALL')) {
                let checkMenuPermission = Helper.findCommonElements(menu.availableTo, rolePermissions);
                menu['is_hidden'] = !checkMenuPermission;
                if (menu['children'] && menu['children'].length > 0 && menu['children'][0]) {
                  if (menu['children'][0] && menu['children'][0]['children'] && menu['children'][0] && menu['children'][0]['children'].length > 0) {
                    menu['children'][0]['children'].forEach(child => {
                      let checkSubMenuPermission = Helper.findCommonElements(child.availableTo, rolePermissions);
                      child['is_hidden'] = !checkSubMenuPermission;
                    });
                  }
                }
              } else {
                menu['is_hidden'] = false;
              }
            } else {
              menu['is_hidden'] = true;
            }
          });
        }
      }
    });
  }

  // Windows width
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }
}
