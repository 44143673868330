import {HttpClient, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from './shared/shared.module';
import {LoginComponent} from './auth/login/login.component';
import {GlobalService} from './shared/services/global.service';
import {AlertService} from './shared/services/alert.service';
import {SnotifyModule, SnotifyService, ToastDefaults} from 'ng-snotify';
import {HtpInterceptor} from './shared/services/interceptor.service';
import {NotificationComponent} from './components/notification/notification.component';
import {AddNotificationComponent} from './components/notification/add-notification/add-notification.component';
import {ProfileComponent} from './components/profile/profile.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IssuesComponent} from './components/issues/issues.component';
import {CountToModule} from 'angular-count-to';
import { CollegesComponent } from './components/colleges/colleges.component';
import { ReferredByComponent } from './components/referred-by/referred-by.component';
import { EnquiryComponent } from './components/enquiry/enquiry.component';
import { ExamComponent } from './components/exam/exam.component';
import { LevelsComponent } from './components/levels/levels.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NotificationComponent,
    AddNotificationComponent,
    ProfileComponent,
    IssuesComponent,
    CollegesComponent,
    ReferredByComponent,
    EnquiryComponent,
    ExamComponent,
    LevelsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    SnotifyModule,
    ImageCropperModule,
    CountToModule,
  ],
  providers: [
    HtpInterceptor,
    GlobalService,
    AlertService,
    {provide: 'SnotifyToastConfig', useValue: ToastDefaults},
    SnotifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
